// initial state
const state = () => ({
    images: [{
            "type": "0",
            "img": "Images/Materiel/Centrale.gif"
        },
        {
            "type": "1",
            "img": "Images/Materiel/Centrale.gif"
        },
        {
            "type": "3",
            "img": "Images/Materiel/Centrale.gif"
        },
        {
            "type": "4",
            "img": "Images/Materiel/CentraleRW.gif"
        },
        {
            "type": "5",
            "img": "Images/Materiel/HFRW.gif"
        },
        {
            "type": "6",
            "img": "Images/Materiel/Integrale.jpg"
        },
        {
            "type": "7",
            "img": "Images/Materiel/Integrale.jpg"
        },
        {
            "type": "8",
            "img": "Images/Materiel/Poignee.gif"
        },
        {
            "type": "9",
            "img": "Images/Materiel/IntraLight.gif"
        },
        {
            "type": "10",
            "img": "Images/Materiel/aucun.png"
        },
        {
            "type": "11",
            "img": "Images/Materiel/minh.png"
        },
        {
            "type": "12",
            "img": "Images/Materiel/tableauaffichage.png"
        },
        {
            "type": "13",
            "img": "Images/Materiel/BoitierParking.png"
        },
        {
            "type": "14",
            "img": "Images/Materiel/hrec3.png"
        },
        {
            "type": "15",
            "img": "Images/Materiel/aucun.png"
        },
        {
            "type": "16",
            "img": "Images/Materiel/Centrale.gif"
        },
        {
            "type": "17",
            "img": "Images/Materiel/Centrale.gif"
        },
        {
            "type": "18",
            "img": "Images/Materiel/Centrale.gif"
        },
        {
            "type": "19",
            "img": "Images/Materiel/hrec3.png"
        },
        {
            "type": "20",
            "img": "Images/Materiel/aucun.png"
        },
        {
            "type": "21",
            "img": "Images/Materiel/aucun.png"
        },
        {
            "type": "22",
            "img": "Images/Materiel/Centrale.gif"
        },
        {
            "type": "23",
            "img": "Images/Materiel/aucun.png"
        },
        {
            "type": "24",
            "img": "Images/Materiel/aucun.png"
        }
    ]
})

// getters
const getters = {
    getImageByType: state => type => {
        return state.images.find(element => parseInt(element.type) === type) != null ?
            state.images.find(element => parseInt(element.type) === type).img : "Images/Materiel/vide.gif";
    }
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}